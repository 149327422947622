// Translation strings
import messagesBe from "./locales/nl-be.json";
import messagesNl from "./locales/nl-nl.json";
import messagesEn from "./locales/en.json";
import messagesFr from "./locales/fr.json";

import localeEn from "react-intl/locale-data/en";
import localeNl from "react-intl/locale-data/nl";
import localeFr from "react-intl/locale-data/fr";

const messages = {
    en: messagesEn,
    "fr-be": messagesFr,
    nl: messagesBe,
    "nl-be": messagesBe,
    "nl-nl": messagesNl
};

export const getLocale = () =>
    document.querySelector("html").getAttribute("lang");

export const getMessages = locale => {
    return messages[locale];
};

export const locales = [...localeEn, ...localeFr, ...localeNl];
