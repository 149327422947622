import {
    ClassConsumer,
    STATUS_CONTACTED,
    STATUS_FINISHED
} from "../../../data/class";
import moment from "moment";

const classConsumer = new ClassConsumer();

export const updateClass = appointment => {
    // reopen the class if needed
    let klass = appointment.klass;
    if (!appointment.id) {
        let klassPromise;

        if (typeof klass === "number") {
            klassPromise = classConsumer.read(appointment.klass);
        } else {
            klassPromise = Promise.resolve(klass);
        }

        klassPromise.then(klass => {
            if (klass._status === STATUS_FINISHED) {
                classConsumer.setStatus(klass.id, STATUS_CONTACTED);
            }
        });
    }
};
export const MOBILE_SCREEN = 1024;
export const isMobile = () => window.innerWidth <= MOBILE_SCREEN;

export const getAppointmentsDuration = appointments => {
    return appointments.reduce((sum, event) => {
        sum += moment.duration(moment(event.end).diff(event.start)).asHours();

        return sum;
    }, 0);
};

export const getTotalAgreedHours = (klass_id, classes) => {
    if (classes && klass_id !== undefined) {
        if (classes.length === 1) {
            return Number(classes[0].agreed_hours);
        } else if (classes.length > 1) {
            const cl = classes.find(c => Number(c.id) === Number(klass_id));
            return cl ? Number(cl.agreed_hours) : 0;
        } else {
            return 0;
        }
    }
    return 0;
};

export const getCurrentPeriod = (klass_id, classes) => {
    if (classes && klass_id !== undefined) {
        if (classes.length === 1) {
            return classes[0].current_period;
        } else if (classes.length > 1) {
            const cl = classes.find(c => Number(c.id) === Number(klass_id));
            return cl ? cl.current_period : 0;
        } else {
            return [null, null];
        }
    }
    return [null, null];
};

export const getClassProperty = (klass_id, classes, propertyName) => {
    if (classes && klass_id !== undefined) {
        if (classes.length === 1) {
            return classes[0][propertyName];
        } else if (classes.length > 1) {
            const cl = classes.find(c => Number(c.id) === Number(klass_id));
            return cl ? cl[propertyName] : 0;
        } else {
            return [null, null];
        }
    }
    return [null, null];
};

export const getFullName = user => {
    if (!user) return "";
    return `${user.first_name} ${user.last_name}`;
};
