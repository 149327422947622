import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const Title = ({ plain, children, className = "" }) => {
    const clName = classNames(
        "interactive-signup__title",
        {
            "interactive-signup__title--plain": plain
        },
        className
    );
    return <h3 className={clName}>{children}</h3>;
};

Title.propTypes = {
    plain: PropTypes.bool
};

Title.defaultProps = {
    plain: false
};
