export default class Snackbar {
    static send_message(message) {
        const snackbar = document.getElementById("snackbar");
        snackbar.innerHTML = message;
        snackbar.classList.toggle("show", true);
        setTimeout(function() {
            snackbar.classList.toggle("show", false);
        }, 5000);
    }
}
