export default class ScrollEffects {
    static init() {
        $("#menu-toggle").on("click", () => {
            $(".react-interactive-signup").toggleClass("index");
        });

        $(".menu__link").on("click", () => {
            $(".navbar-collapse").removeClass("in");
        });

        const navbar = document.querySelector(".navigation");

        function checkScroll() {
            if (window.scrollY > 10) {
                navbar.classList.add("scrolled");
            } else {
                navbar.classList.remove("scrolled");
            }
        }
        if (navbar) {
            // Check scroll position on page load
            checkScroll();

            // Check scroll position on scroll event
            window.addEventListener("scroll", checkScroll);
        }
    }
}
