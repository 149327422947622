/**
 * Convert a nested object into a flat object with dot separated keys.
 * @type {Object}
 */
export const flattenObject = (nestedObject, keyPrefix = "") => {
    let result = {};
    for (let key in nestedObject) {
        let val = nestedObject[key];
        if (typeof val === "object" && !(val instanceof Array)) {
            let nested = flattenObject(val, `${keyPrefix}${key}.`);
            result = Object.assign(result, nested);
        } else {
            result[`${keyPrefix}${key}`] = nestedObject[key];
        }
    }
    return result;
};
