import Snackbar from "./snackbar";
import { getLocale, getMessages } from "../messages";
import { injectIntl, defineMessages } from "react-intl";
import CookiesHelper from "./cookies";

defineMessages({
    couponApplied: {
        id: "coupons.coupon_applied",
        defaultMessage: "Coupon <b>{coupon}</b> will be applied after signup"
    }
});

export default class Coupon {
    static init() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const couponCode = urlParams.get("coupon");
        const locale = getLocale() || "nl";
        const messages = getMessages(locale);

        if (couponCode) {
            const couponAppliedMessage = messages[
                "coupons.coupon_applied"
            ].replace("{coupon}", couponCode);
            CookiesHelper.createCookie("coupon", couponCode);
            setTimeout(() => {
                Snackbar.send_message(couponAppliedMessage);
            }, 2000);
        }
    }
}
