import { CourseSelect } from "../student-signup/CourseSelect";
import React from "react";
import { msg } from "../translations/components/Message";
import { messages } from "./messages";
import { components } from "react-select";
import { CourseConsumer } from "../../../data/course";
import { useAsync } from "react-use";

const courseConsumer = new CourseConsumer();

export const HomePageCourseSelect = ({ onNextStep }) => {
    const { value: quickCourses = [] } = useAsync(async () => {
        return courseConsumer.list({ is_quick_choice: true });
    });

    return (
        <div className="course-select">
            <CourseSelect
                onSelect={({ value, label }) => {
                    onNextStep({ id: value, name: label });
                }}
                selectedCourses={[]}
                components={{
                    Control,
                    DropdownIndicator() {
                        return (
                            <DropdownIndicator
                                onNextStep={() => onNextStep()}
                            />
                        );
                    }
                }}
            />
            {!!quickCourses.length && (
                <ul className="signup-course__list">
                    {quickCourses.map(course => (
                        <CourseItem
                            key={course.id}
                            {...course}
                            onClick={() => {
                                onNextStep(course);
                            }}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

function DropdownIndicator({ onNextStep }) {
    return (
        <button
            onClick={onNextStep}
            className="btn btn__primary btn__primary--lg"
        >
            {msg(messages.getHelp)}
        </button>
    );
}

function Control({ children, ...props }) {
    return (
        <components.Control {...props}>
            <i
                className="far fa-chalkboard-teacher"
                style={{ fontSize: 20, marginLeft: 10 }}
            ></i>
            {children}
        </components.Control>
    );
}

function CourseItem({ name, icon, onClick }) {
    return (
        <li className="signup-course__list-item" onClick={onClick}>
            {icon && <img src={icon} alt={`Icon for ${name} course`} />}
            {name}
        </li>
    );
}
