import { defineMessages } from "react-intl";

export const messages = defineMessages({
    makeChoice: {
        id: "student-signup.makeChoice",
        defaultMessage: "Or make a quick choice:"
    },
    chooseOneOrMore: {
        id: "student-signup.chooseOneOrMore",
        defaultMessage: "Choose one or more!"
    },
    selectedCourses: {
        id: "student-signup.selectedCourses",
        defaultMessage: "Selected courses:"
    },
    selectPlaceholder: {
        id: "student-signup.selectPlaceholder",
        defaultMessage: "Find your teacher"
    },
    formatCreateLabel: {
        id: "student-signup.formatCreateLabel",
        defaultMessage: "Others: {inputValue}"
    },
    search: {
        id: "student-signup.search",
        defaultMessage: "Search"
    }
});
