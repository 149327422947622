import { CrudConsumer, CrudConsumerObject } from "consumerjs";
import { includeLanguagePrefix } from "../utils/general";

export const STATUS_FINISHED = "finished";
export const STATUS_CONTACTED = "contacted";

/**
 * A received Class object.
 * @class
 */
class Class extends CrudConsumerObject {}

/**
 * Provides logic for creating, reading, updating and deleting Class objects.
 * @class
 */
export class ClassConsumer extends CrudConsumer {
    constructor(endpoint = "/api/classes/", objectClass = Class) {
        super(includeLanguagePrefix(endpoint), objectClass);
    }

    setStatus(pk, status) {
        return this.post(`${pk}/status/`, { status });
    }

    getClass(pk) {
        return this.read(pk);
    }
}
