import AOS from "aos";
import "aos/dist/aos.css";
import Swiper, { options } from "../../components/swiper";
import { renderInteractiveSignup } from "./interactive-signup";
import { isMobile } from "../../components/react/appointments/utils";

export class HomePage {
    static init() {
        new Swiper("#swiper-testimonials", options);
        new Swiper("#swiper-carousel", {
            ...options,
            autoplay: false,
            speed: 300
        });

        if (isMobile()) {
            new Swiper("#swiper-post-list", {
                ...options,
                autoplay: false,
                speed: 500,
                pagination: {
                    el: ".swiper-pagination-blog"
                },
                navigation: {
                    prevEl: ".swiper-button-prev-blog",
                    nextEl: ".swiper-button-next-blog"
                }
            });
        }
        setContentHeight();
        positionHighlightedColumn();
        initSignup();
        initMenu();
        AOS.init();
        // Add scroll event listener
        window.addEventListener("scroll", applyZoomEffect);

        // Initial call to set initial state
        applyZoomEffect();
        window.addEventListener("resize", positionHighlightedColumn);
        window.addEventListener("resize", setContentHeight);
    }
}

function setContentHeight() {
    const contents = document.querySelectorAll(
        ".gallery-block__content.dynamic"
    );
    let maxHeight = 0;

    // Find the maximum height
    contents.forEach(content => {
        maxHeight = Math.max(maxHeight, content.clientHeight);
    });

    // Set the maximum height to all elements
    contents.forEach(content => {
        content.style.height = `${maxHeight}px`;
    });
}

function positionHighlightedColumn() {
    const table = document.querySelector(".features__table");

    if (!table) return;
    const highlightedCells = table.querySelectorAll(".highlighted");

    if (highlightedCells.length > 0) {
        const firstCell = highlightedCells[0];
        const rect = firstCell.getBoundingClientRect();
        const tableRect = table.getBoundingClientRect();

        const left = rect.left - tableRect.left;
        const width = rect.width;

        table.style.setProperty("--highlight-left", `${left}px`);
        table.style.setProperty("--highlight-width", `${width}px`);
    }
}

function initSignup() {
    renderInteractiveSignup();
}

function initMenu() {
    const toggleNode = document.getElementById("menu-toggle");
    const closeBtn = document.getElementById("menu-close");

    if (!toggleNode) {
        return;
    }

    toggleNode.addEventListener("click", () => {
        document.getElementById("navbar-collapse").classList.add("open");
    });

    closeBtn.addEventListener("click", () => {
        document.getElementById("navbar-collapse").classList.remove("open");
    });
}

const elements = document.querySelectorAll(".scroll-zoom");

const initialScale = 1;

const maxZoom = 1.2;

let lastScrollPosition = window.scrollY;

function applyZoomEffect() {
    const currentScrollPosition = window.scrollY;
    const isScrollingDown = currentScrollPosition > lastScrollPosition;
    const maxScroll =
        document.documentElement.scrollHeight - window.innerHeight;

    elements.forEach(element => {
        const rect = element.getBoundingClientRect();
        const elementTop = rect.top + currentScrollPosition;
        const elementBottom = elementTop + rect.height;

        const visibleTop = Math.max(elementTop, currentScrollPosition);
        const visibleBottom = Math.min(
            elementBottom,
            currentScrollPosition + window.innerHeight
        );
        const visibleHeight = Math.max(0, visibleBottom - visibleTop);

        let visibilityRatio = visibleHeight / rect.height;
        let scrollProgress = currentScrollPosition / maxScroll;

        let zoomFactor;
        if (isScrollingDown) {
            zoomFactor =
                initialScale +
                (maxZoom - initialScale) * visibilityRatio * scrollProgress;
        } else {
            zoomFactor =
                maxZoom -
                (maxZoom - initialScale) *
                    visibilityRatio *
                    (1 - scrollProgress);
        }

        zoomFactor = Math.max(initialScale, Math.min(maxZoom, zoomFactor));

        element.style.transform = `scale(${zoomFactor})`;
    });

    lastScrollPosition = currentScrollPosition;
}
