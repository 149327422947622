import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export class Overlay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modifier: ""
        };
    }

    componentDidMount() {
        window.setTimeout(() => {
            this.setState({ modifier: "dark" });
        }, 20);
    }

    render() {
        const { children, focus, onClose } = this.props;
        const { modifier } = this.state;

        const className = classNames("interactive-signup-overlay", {
            "interactive-signup-overlay__focused": focus,
            [`interactive-signup-overlay__focused--${modifier}`]:
                focus && !!modifier
        });

        return (
            <div className={className}>
                {focus && (
                    <i
                        className="fa fa-times-circle fa-4x interactive-signup-overlay__close-icon"
                        onClick={onClose}
                    />
                )}
                {children}
            </div>
        );
    }
}

Overlay.propTypes = {
    children: PropTypes.node,
    focus: PropTypes.bool
};
