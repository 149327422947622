import React from "react";
import { msg } from "../translations/components/Message";
import messages from "./messages";
import { Title } from "./Title";

const ErrorScreen = () => {
    return (
        <div className="error-screen__wrapper">
            <Title className="error-screen__header">
                <i className="fa fa-warning" />
                {msg(messages.signupError)}
            </Title>
            <p className="error-screen__text">{msg(messages.errorText)}</p>

            <a href="/" className="btn btn__wide btn__secondary">
                {msg(messages.tryAgain)}
            </a>
        </div>
    );
};

export default ErrorScreen;
