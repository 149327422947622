import { defineMessages } from "react-intl";

export default defineMessages({
    getQuote: {
        id: "interactive.signup.btn.quote",
        defaultMessage: "Get a quote"
    },
    student: {
        id: "interactive.signup.card.student",
        defaultMessage: "Student"
    },
    prof: {
        id: "interactive.signup.card.prof",
        defaultMessage: "Professional"
    },
    teaching: {
        id: "interactive.signup.teacher.teaching",
        defaultMessage: "Teaching?"
    },
    teacherSignup: {
        id: "interactive.signup.teacher.link",
        defaultMessage: "Sign up here"
    },
    guidanceTitle: {
        id: "interactive.signup.guidance.title",
        defaultMessage: "I am looking for guidance for:"
    },
    level: {
        id: "interactive.signup.level",
        defaultMessage: "Level"
    },
    topic: {
        id: "interactive.signup.topic",
        defaultMessage: "Topic"
    },
    noResults: {
        id: "interactive.signup.autocomplete.no.results",
        defaultMessage: "No results found"
    },
    postcodeInput: {
        id: "interactive.signup.postcode.input",
        defaultMessage: "What is your postcode?"
    },
    postcode: {
        id: "interactive.signup.postcode",
        defaultMessage: "Postcode"
    },
    myPostcode: {
        id: "interactive.signup.placeholder.postcode",
        defaultMessage: "My postcode, for ex. 3102"
    },
    coursePlaceholder: {
        id: "interactive.signup.course.placeholder",
        defaultMessage: "French, Maths, other?"
    },
    helpTitle: {
        id: "interactive.signup.help.title",
        defaultMessage: "How can we help?"
    },
    moreInfo: {
        id: "interactive.signup.question.more.info",
        defaultMessage: "More info about the question"
    },
    contactMeans: {
        id: "interactive.signup.contact.means",
        defaultMessage: "How can we reach you?"
    },
    email: {
        id: "interactive.signup.contact.email",
        defaultMessage: "E-mail address"
    },
    phone: {
        id: "interactive.signup.contact.phone",
        defaultMessage: "Tel / GSM"
    },
    password: {
        id: "interactive.signup.form.password",
        defaultMessage: "Password"
    },
    passwordMin: {
        id: "interactive.signup.form.password.minimum",
        defaultMessage: "Minimum 6 characters"
    },
    setPassword: {
        id: "interactive.signup.form.password.set",
        defaultMessage: "Set password"
    },
    completed: {
        id: "interactive.signup.completed",
        defaultMessage: "100% complete :) - What now?"
    },
    done: {
        id: "interactive.signup.done",
        defaultMessage: "Done! Thank you!"
    },
    nameInput: {
        id: "interactive.signup.name.input",
        defaultMessage: "What is your name?"
    },
    companyName: {
        id: "interactive.signup.company.name",
        defaultMessage: "Company name"
    },
    firstName: {
        id: "interactive.signup.name.first",
        defaultMessage: "First name"
    },
    lastName: {
        id: "interactive.signup.name.last",
        defaultMessage: "Last name"
    },
    nextStep: {
        id: "interactive.signup.nav.step.next",
        defaultMessage: "Next step"
    },
    selectLevel: {
        id: "interactive.signup.select.placeholder",
        defaultMessage: "Select level"
    },
    gdprText: {
        id: "interactive.signup.footnote.gdpr.text",
        defaultMessage: "Information is protected according to GDPR rules."
    },
    signupError: {
        id: "interactive.signup.error.header",
        defaultMessage: "Signup Error"
    },
    errorText: {
        id: "interactive.signup.error.text",
        defaultMessage:
            "Error when trying to process the request. Please try again or contact our customer support if the error persists."
    },
    tryAgain: {
        id: "interactive.signup.try.again.text",
        defaultMessage: "Try again"
    },
    finalising: {
        id: "interactive.signup.finalising",
        defaultMessage:
            "Get your ducks in a row \uD83E\uDD86 \uD83E\uDD86 \uD83E\uDD86"
    },
    finalisingSub: {
        id: "interactive.signup.finalising.subtitle",
        defaultMessage: "Should only take a moment."
    },
    completeStep: {
        id: "interactive.signup.steps.complete",
        defaultMessage: "Voltooien"
    },
    spamHint: {
        id: "interactive.signup.email.hint",
        defaultMessage:
            "Please check your spam folder for the confirmation email."
    },
    otherPlaceholder: {
        id: "interactive.signup.courses.other.placeholder",
        defaultMessage: "Specify course name"
    },
    street: {
        id: "interactive.signup.street",
        defaultMessage: "Street"
    },
    streetPlaceholder: {
        id: "interactive.signup.street.placeholder",
        defaultMessage: "Street name"
    },
    houseNumber: {
        id: "interactive.signup.house.number",
        defaultMessage: "House number"
    },
    houseNumberPlaceholder: {
        id: "interactive.signup.house.number.placeholder",
        defaultMessage: "House number"
    },
    year: {
        id: "interactive.signup.year",
        defaultMessage: "Year"
    }
});
