import React from "react";
import { msg } from "../translations/components/Message";
import messages from "./messages";
import { Title } from "./Title";

export const Loader = ({ message }) => {
    return (
        <div className="loader__wrapper">
            <Title>{msg(messages.finalising)}</Title>
            <span className="loader__text">
                {message || msg(messages.finalisingSub)}
                <i className="fas fa-spinner loader__icon" />
            </span>
        </div>
    );
};
