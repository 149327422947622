import Swiper from "swiper";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";

export const options = {
    spaceBetween: 16,
    centeredSlides: true,
    loop: true,
    freeMode: true,
    slidesPerView: 4,
    breakpoints: {
        380: {
            slidesPerView: 1,
            spaceBetween: 16
        },
        768: {
            slidesPerView: 2
        },
        1024: {
            slidesPerView: 3
        }
    },
    autoplay: {
        delay: 0,
        disableOnInteraction: false
    },
    speed: 10000,
    modules: [Autoplay, Pagination, Navigation],
    pagination: {
        el: ".swiper-pagination"
    },
    navigation: {
        nextEl: ".swiper-button-next"
    }
};

export default Swiper;
