import { CrudConsumer, CrudConsumerObject } from "consumerjs";
import { excludeUnserializableFields } from "consumerjs/src/utils";
import { includeLanguagePrefix } from "../utils/general";

/**
 * A received Course object.
 * @class
 */
class Course extends CrudConsumerObject {}

/**
 * Provides logic for creating, reading, updating and deleting Course objects.
 * @class
 */
export class CourseConsumer extends CrudConsumer {
    constructor(endpoint = "/api/courses/", objectClass = Course) {
        super(includeLanguagePrefix(endpoint), objectClass);
    }

    /**
     * Filter a list of courses, based on a partial case insensitive name
     * @param  {String} partialName Search string
     * @param {String} type Course type
     * @return {Promise}
     */
    search(partialName, type) {
        let query = partialName ? { name__icontains: partialName } : "";
        if (type) {
            query.type = type;
        }
        return this.get("", query).then((resp = []) => {
            return resp.map(r => excludeUnserializableFields(r));
        });
    }

    list(query = {}) {
        return this.get("", query).then((resp = []) => {
            return resp.map(r => excludeUnserializableFields(r));
        });
    }
}
