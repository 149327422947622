import { messages } from "./messages";
import React, { useState } from "react";
import { CourseConsumer } from "../../../data/course";
import { components } from "react-select";
import Creatable from "react-select/creatable";
import { injectIntl } from "react-intl";
import { useAsync } from "react-use";

const { Option } = components;
const courseConsumer = new CourseConsumer();

const styles = {
    control: (baseStyles, { isFocused }) => ({
        ...baseStyles,
        borderRadius: "50px",
        padding: "0 8px",
        cursor: "pointer",
        borderColor: "transparent",
        boxShadow: isFocused
            ? "0 0 0 1px #71D38E"
            : "0px 0px 30px 0px rgba(0, 0, 0, 0.10)",
        zIndex: 1,

        "&:hover": {
            borderColor: "#71D38E"
        }
    }),
    valueContainer: baseStyles => ({
        ...baseStyles,
        padding: 16
    }),
    placeholder: baseStyles => ({
        ...baseStyles,
        fontSize: window.innerWidth < 768 ? 14 : 16
    }),
    menu: baseStyles => ({
        ...baseStyles,
        borderRadius: 12,
        zIndex: 1
    }),
    menuList: baseStyles => ({
        ...baseStyles,
        borderRadius: 12
    }),
    option: (baseStyles, { isFocused }) => ({
        ...baseStyles,
        backgroundColor: isFocused ? "#71D38E" : "transparent",
        color: isFocused ? "white" : "#0e0e2c",
        fontSize: 16,
        padding: "12px",
        cursor: "pointer"
    })
};

function CourseSelectBase({
    intl,
    onSelect,
    selectedCourses = [],
    openMenuOnClick = true,
    ...props
}) {
    const [value, setValue] = useState("");
    const state = useAsync(async () => {
        return courseConsumer.list().then(items => {
            return items.map(course => ({
                value: course.id,
                label: course.name,
                icon: course.icon
            }));
        });
    });

    const formatCreateLabel = inputValue => {
        return intl.formatMessage(messages.formatCreateLabel, { inputValue });
    };

    return (
        <Creatable
            value={value}
            isClearable
            options={state?.value?.filter(
                course => !selectedCourses.includes(course.value)
            )}
            components={{
                Option: IconOption,
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
                ...props.components
            }}
            width={"100%"}
            placeholder={intl.formatMessage(messages.selectPlaceholder)}
            formatCreateLabel={formatCreateLabel}
            styles={styles}
            onChange={course => {
                onSelect(course);
                setValue(null);
            }}
            onMenuOpen={() =>
                document.querySelector("body").classList.add("overlay")
            }
            onMenuClose={() =>
                document.querySelector("body").classList.remove("overlay")
            }
            openMenuOnClick={openMenuOnClick}
        />
    );
}

export const CourseSelect = injectIntl(CourseSelectBase);

function IconOption(props) {
    return (
        <Option {...props}>
            {props.data.icon && (
                <img
                    className={"signup-course__list-icon"}
                    src={props.data.icon}
                    style={{ width: 36 }}
                    alt={props.data.label}
                />
            )}
            {props.data.label}
        </Option>
    );
}
